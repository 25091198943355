<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a [routerLink]="['/']" class="logo-text float-left">
      <div class="logo-img">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 2160 2900" style="enable-background:new 0 0 2160 3500;" xml:space="preserve">
          <style type="text/css">
            .st0 {
              clip-path: url(#SVGID_00000086656232223604715030000009376649401621912984_);
              fill: #FFFFFF;
            }

            .st1 {
              clip-path: url(#SVGID_00000040576248847337543400000008382484066833278081_);
              fill: #FFFFFF;
            }
          </style>
          <g>
            <g id="_x3C_Mirror_Repeat_x3E__00000183210485348654330420000004791164856042789037_">
              <g>
                <defs>
                  <rect id="SVGID_1_" x="119" y="461.5" width="968.5" height="1784" />
                </defs>
                <clipPath id="SVGID_00000056424677133474534840000005619608830305685142_">
                  <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <path style="clip-path:url(#SVGID_00000056424677133474534840000005619608830305685142_);fill:#FFFFFF;" d="M2066.3,461.5v1784
				h-812.5c-0.4-26.1,3.7-46.7,5.4-59c4.9-37.1,24.1-80.2,39.6-133.7c10.1-34.5,25.1-79.7,39.4-122.8c8-23.9,14.6-56.1,19.3-82.4
				c4.7-26.2,7.3-46.5,7.3-46.5c0,0,73.9-48.2,105.8-72.8c34.9-26.9,95.4-87.1,114.7-113.7c11.9-16.4,36.5-49.4,53.9-75.8
				c11.5-17.5,25-37.8,32.1-47.1c2.7-3.6,5.6-11.5,7.3-15.6c14.6-35,33.1-92.5,39.4-118.9c8.9-36.9,17.9-98.9,22.1-129.7
				c1.8-13.1,4.1-26.1,7-38.9c4.3-19.2,10.5-47,13.7-60.5c5.2-21.9,7.1-36.2,15.1-76.1c4.6-23,2.1-38.6-4-58.9c-3-10-3-19-15-22
				c-3.9-1-4.7-0.9-9-1c-4.2-0.1-19-1.3-24-1c-5.1,0.3-10,1.7-15,3c-10.2,2.7-17.2-0.3-33.2,6c-7.7,3-11.7,9-12.7,17.2
				c-2.4,18.2,2.4,17.6-3.9,21.7c-3.4,1.3-5,2-9,8c-3.1,4.6-1.2,10.4,2,15c8.5,12.3,28.4,55.9,29.9,68.9c3,24.9-16.5,64-16.5,64
				l-28.4,62.7c-11.7,22.2-28.3,62.1-37.9,94.8c-6,20.4-13.2,43.4-18.8,61.3c-5.7,17.9-9.9,30.6-9.9,30.6c0,0-27.7,27.6-63.1,56.7
				c-17.9,14.8-40.1,40.9-57.8,63.4c-17.7,22.5-30.9,41.3-31,41.3c-0.1,0-107.8,30.9-136.7,46.9c-61.3,33.8-83.5,64.4-83.8,64.8
				c0,0,0,0,0,0l-2.8-1.2c-11.3-4.9-21.5-15.6-26.7-26.8c0-0.1-0.1-0.2-0.1-0.2c-2.3-5.1-4.8-11.1-6.3-16.7c-4-17-5-25.9-3-43.9
				c0.8-6.9,2.4-14.3,7-24.9c10-23,9-20.3,9.3-20.1c7.7,3.2,14.2,0.7,17.6-11.8c1.1-4.1,4-12.6,6-20.5c1-3.6,1.9-7.2,2.9-10.8
				c3.5-11.4,6.9-25.4,5.8-30c-0.5-2.3-2.4-4.1-4.7-4.6c-1.2-0.2-1.9-0.3-3.7,0.1c0,0,0,0,0,0c0.1-0.7,5.2-37.9,0.1-65
				c-8.6-45.4-25.3-70.7-64.2-91.6c-32.6-13.2-62.9-17-97.8-2c-35.5,15.2-60.9,51.9-66.8,84.8c-5,24.9-7.6,50.1-3.2,72.8
				c0,0,0,0,0,0h-0.1c-2.3,0-4.6,0.9-6.2,2.6c-0.2,0.2-0.3,0.3-0.4,0.5c-1.9,2.4-2.3,5.5-1.4,8.4l6.3,32.2l5.2,19.8v0
				c2.5,7.3,5.3,12.3,12.8,13.3c1.7,0.2,3.4-0.1,4.9-0.8c0,0,0,0,0,0c0.1,0.1,5,9.9,13,24.8c7,13,8,29.9,9,43.9c0,3.5,0,16-3,27.9
				c-0.5,1.9-2,5.5-3.5,9.1c-5.1,11.6-14.4,21-26.1,25.9c-1.2,0.5-2.2,0.9-2.9,1.1c-0.7,0.2-1.1,0.4-1.1,0.4
				c-5-4.7-17.7-20.1-44.2-42.4c-31.9-26.9-69.6-42.8-99.3-51.9c-29.7-9.1-51.4-11.5-51.4-11.5c0,0-29.8-39.5-71.8-73.3
				c-14.9-12-34.1-25.7-49.6-36.5c-15.5-10.8-27.2-18.6-27.2-18.6c0-0.1-21.6-68.3-37.4-108.6c-9.5-24.3-21.8-46.7-33.9-71.3
				c-7-14.1-17.7-37-22.9-51.8c-8.5-24.5-16-50.4-21.5-72.3c-2-8-5-31.9-2-38.9c2.6-6.1,11.9-31.9,23.1-56.7
				c5.4-11.9,1.6-18.2-3.5-23.9c-1-1.1-2.2-2-3.5-2.8l-5.7-3c0,0,0,0,0,0c5.6-26.5,6.6-15-2.7-39.6c-3.3-8.6-6.3-14.6-15.5-15.6
				l-18,1c-2.6-0.3-8-1-15,0c-7,1-8,2-17,3c-6.9,0.8-16.8-0.8-24.9,2c-9.2,3.1-10.3,4-14,13l-4.8,23.3c-0.3,1.1-0.7,2.2-1.1,3.3
				c-2.9,8.2-2.5,14.1-0.6,22.6c6,26.8,15.4,60.2,21.2,74.5c7.8,19,5.3,47.8,9.5,74.9c5.3,34.2,23.2,166.8,34.6,205.2
				c8.6,28.9,27.5,81.2,32.8,98.9c1.6,5.2,4.1,10.1,7.4,14.4c8.7,11,21.2,23.1,36.6,49.6c19.5,33.4,65.7,78.5,93.8,109.7
				c22,24.5,59.5,56.4,91.4,82.2c32,25.8,58.5,45.6,58.5,45.6c0,0.1,19.6,91.7,31.2,133.7c9.1,32.8,54.6,159.2,62.2,184.9
				c11.7,39.9,21.3,71.5,24,113c0,5.7-0.5,11.5-1.3,17.4H119v-1784h513.2l460.9,625.3l230.4-312.7L1554,461.5H2066.3z" />
              </g>
            </g>
            <g id="_x3C_Mirror_Repeat_x3E__00000099642519754913705660000013968605125312223909_">
              <g>
                <defs>
                  <rect id="SVGID_00000021836212422216202280000010144564561902184625_" x="1087.5" y="461.5"
                    width="968.5" height="1784" />
                </defs>
                <clipPath id="SVGID_00000075146884575610325490000007929539369662963593_">
                  <use xlink:href="#SVGID_00000021836212422216202280000010144564561902184625_"
                    style="overflow:visible;" />
                </clipPath>
                <path style="clip-path:url(#SVGID_00000075146884575610325490000007929539369662963593_);fill:#FFFFFF;" d="M108.7,461.5v1784
				h812.5c0.4-26.1-3.7-46.7-5.4-59c-4.9-37.1-24.1-80.2-39.6-133.7c-10.1-34.5-25.1-79.7-39.4-122.8c-8-23.9-14.6-56.1-19.3-82.4
				c-4.7-26.2-7.3-46.5-7.3-46.5c0,0-73.9-48.2-105.8-72.8c-34.9-26.9-95.4-87.1-114.7-113.7c-11.9-16.4-36.5-49.4-53.9-75.8
				c-11.5-17.5-25-37.8-32.1-47.1c-2.7-3.6-5.6-11.5-7.3-15.6c-14.6-35-33.1-92.5-39.4-118.9c-8.9-36.9-17.9-98.9-22.1-129.7
				c-1.8-13.1-4.1-26.1-7-38.9c-4.3-19.2-10.5-47-13.7-60.5c-5.2-21.9-7.1-36.2-15.1-76.1c-4.6-23-2.1-38.6,4-58.9c3-10,3-19,15-22
				c3.9-1,4.7-0.9,9-1c4.2-0.1,19-1.3,24-1c5.1,0.3,10,1.7,15,3c10.2,2.7,17.2-0.3,33.2,6c7.7,3,11.7,9,12.7,17.2
				c2.4,18.2-2.4,17.6,3.9,21.7c3.4,1.3,5,2,9,8c3.1,4.6,1.2,10.4-2,15c-8.5,12.3-28.4,55.9-29.9,68.9c-3,24.9,16.5,64,16.5,64
				l28.4,62.7c11.7,22.2,28.3,62.1,37.9,94.8c6,20.4,13.2,43.4,18.8,61.3c5.7,17.9,9.9,30.6,9.9,30.6c0,0,27.7,27.6,63.1,56.7
				c17.9,14.8,40.1,40.9,57.8,63.4c17.7,22.5,30.9,41.3,31,41.3c0.1,0,107.8,30.9,136.7,46.9c61.3,33.8,83.5,64.4,83.8,64.8
				c0,0,0,0,0,0l2.8-1.2c11.3-4.9,21.5-15.6,26.7-26.8c0-0.1,0.1-0.2,0.1-0.2c2.3-5.1,4.8-11.1,6.3-16.7c4-17,5-25.9,3-43.9
				c-0.8-6.9-2.4-14.3-7-24.9c-10-23-9-20.3-9.3-20.1c-7.7,3.2-14.2,0.7-17.6-11.8c-1.1-4.1-4-12.6-6-20.5c-1-3.6-1.9-7.2-2.9-10.8
				c-3.5-11.4-6.9-25.4-5.8-30c0.5-2.3,2.4-4.1,4.7-4.6c1.2-0.2,1.9-0.3,3.7,0.1c0,0,0,0,0,0c-0.1-0.7-5.2-37.9-0.1-65
				c8.6-45.4,25.3-70.7,64.2-91.6c32.6-13.2,62.9-17,97.8-2c35.5,15.2,60.9,51.9,66.8,84.8c5,24.9,7.6,50.1,3.2,72.8c0,0,0,0,0,0
				h0.1c2.3,0,4.6,0.9,6.2,2.6c0.2,0.2,0.3,0.3,0.4,0.5c1.9,2.4,2.3,5.5,1.4,8.4l-6.3,32.2l-5.2,19.8v0c-2.5,7.3-5.3,12.3-12.8,13.3
				c-1.7,0.2-3.4-0.1-4.9-0.8c0,0,0,0,0,0c-0.1,0.1-5,9.9-13,24.8c-7,13-8,29.9-9,43.9c0,3.5,0,16,3,27.9c0.5,1.9,2,5.5,3.5,9.1
				c5.1,11.6,14.4,21,26.1,25.9c1.2,0.5,2.2,0.9,2.9,1.1c0.7,0.2,1.1,0.4,1.1,0.4c5-4.7,17.7-20.1,44.2-42.4
				c31.9-26.9,69.6-42.8,99.3-51.9c29.7-9.1,51.4-11.5,51.4-11.5c0,0,29.8-39.5,71.8-73.3c14.9-12,34.1-25.7,49.6-36.5
				c15.5-10.8,27.2-18.6,27.2-18.6c0-0.1,21.6-68.3,37.4-108.6c9.5-24.3,21.8-46.7,33.9-71.3c7-14.1,17.7-37,22.9-51.8
				c8.5-24.5,16-50.4,21.5-72.3c2-8,5-31.9,2-38.9c-2.6-6.1-11.9-31.9-23.1-56.7c-5.4-11.9-1.6-18.2,3.5-23.9c1-1.1,2.2-2,3.5-2.8
				l5.7-3c0,0,0,0,0,0c-5.6-26.5-6.6-15,2.7-39.6c3.3-8.6,6.3-14.6,15.5-15.6l18,1c2.6-0.3,8-1,15,0c7,1,8,2,17,3
				c6.9,0.8,16.8-0.8,24.9,2c9.2,3.1,10.3,4,14,13l4.8,23.3c0.3,1.1,0.7,2.2,1.1,3.3c2.9,8.2,2.5,14.1,0.6,22.6
				c-6,26.8-15.4,60.2-21.2,74.5c-7.8,19-5.3,47.8-9.5,74.9c-5.3,34.2-23.2,166.8-34.6,205.2c-8.6,28.9-27.5,81.2-32.8,98.9
				c-1.6,5.2-4.1,10.1-7.4,14.4c-8.7,11-21.2,23.1-36.6,49.6c-19.5,33.4-65.7,78.5-93.8,109.7c-22,24.5-59.5,56.4-91.4,82.2
				c-32,25.8-58.5,45.6-58.5,45.6c0,0.1-19.6,91.7-31.2,133.7c-9.1,32.8-54.6,159.2-62.2,184.9c-11.7,39.9-21.3,71.5-24,113
				c0,5.7,0.5,11.5,1.3,17.4H2056v-1784h-513.2l-460.9,625.3L851.5,774.2L621,461.5H108.7z" />
              </g>
            </g>
          </g>
        </svg>


      </div>
      <span class="text align-middle font-18">Motiversity</span>
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;" (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar] [disabled]="!perfectScrollbarEnable">
  <div class="nav-container">
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <ng-container *ngFor="let menuItem of menuItems">
        <li appSidebarlink [parent]="menuItem.title" *ngIf="menuItem.hasaccess.includes(type)" [path]="menuItem.path"
          level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false"
          [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
          [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'"
          [routerLinkActiveOptions]="{exact: false}">
          <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink">
            <img *ngIf="menuItem.title == 'Weekly Overview'" src="assets/img/icons/dashboard.png"
              style="    width: 16px;            margin-right: 15px;            margin-left: 7px;">
            <i *ngIf="menuItem.title != 'Weekly Overview'" [ngClass]="[menuItem.icon]"></i>

            <span class="menu-title">{{menuItem.title }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
              [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <a appSidebarAnchorToggle routerLink="{{menuItem.path}}"
            *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
            <img *ngIf="menuItem.title == 'Weekly Overview'" src="assets/img/icons/dashboard.png"
              style="    width: 16px;            margin-right: 15px;            margin-left: 7px;">
            <i *ngIf="menuItem.title != 'Weekly Overview'" [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
              [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
              [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <!-- Second level menu -->
          <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0 ">
            <ng-container *ngFor="let menuSubItem of menuItem.submenu">

              <li appSidebarlink *ngIf="menuSubItem.hasaccess.includes(type)" [parent]="menuItem.title"
                [hasSub]="menuSubItem.class.includes('has-sub') ? true: false" [path]="menuSubItem.path"
                level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}"
                [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
                <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
                  <i [ngClass]="[menuSubItem.icon]"></i>

                  <span class="menu-title">{{menuSubItem.title | translate }}</span>
                  <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                    [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                </a>
                <a appSidebarAnchorToggle routerLink="{{menuSubItem.path}}"
                  *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink">
                  <i [ngClass]="[menuSubItem.icon]"></i>

                  <span class="menu-title" *ngIf="menuSubItem.title == 'Dashboard'">
                    <ng-container *ngIf="user$ | async as _user">{{ _user.name }}'s</ng-container>
                    {{menuSubItem.title | translate
                    }}</span>
                  <span class="menu-title" *ngIf="menuSubItem.title != 'Dashboard'">{{menuSubItem.title | translate
                    }}</span>
                  <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                    [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                </a>
                <a routerLink="{{menuSubItem.path}}" target="_blank" *ngIf="menuSubItem.isExternalLink">
                  <i [ngClass]="[menuSubItem.icon]"></i>
                  <span class="menu-title">{{menuSubItem.title | translate }}</span>
                  <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                    [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                </a>
                <!-- Third level menu -->
                <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                  <ng-container *ngFor="let menuSubsubItem of menuSubItem.submenu">
                    <li appSidebarlink *ngIf="menuSubsubItem.hasaccess.includes(type)" [parent]="menuItem.title"
                      [hasSub]="false" [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}"
                      [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
                      <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}"
                        *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                        <i [ngClass]="[menuSubsubItem.icon]"></i>
                        <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                        <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                          [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                      </a>
                      <ng-template #externalSubSubLinkBlock>
                        <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                          <i [ngClass]="[menuSubsubItem.icon]"></i>
                          <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                          <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                            [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                        </a>
                      </ng-template>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->