import { Routes, RouterModule } from "@angular/router";
import { PermissionGuard } from "app/core/service/guard/permission.guard";
import { Roles } from "app/roles";
const {
  ADMIN,
  MANAGER, MANAGER_INSIGHTS,
  CREATOR,
  RIGHT_HOLDER,
  MANAGER_LIMITED,
  VIEWER,
  VIEWER_LIMITED,
} = Roles;

//Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    data: { permissions: "ALLOW_ALL" },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "dashboard-form",
    data: { permissions: "ALLOW_ALL" },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/dashboard-form/dashboard-form.module").then(
        (m) => m.DashboardFormModule
      ),
  },

  {
    path: "dashboard-graphs",
    data: {
      permissions: [
        ADMIN,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/dashboard-graphs/dashboard-graphs.module").then(
        (m) => m.DashboardGraphsModule
      ),
  },
  {
    path: "admins",
    data: { permissions: [ADMIN] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "creators",
    loadChildren: () =>
      import("app/modules/creator/creator.module").then((m) => m.CreatorModule),
  },
  {
    path: "contracts",
    data: {
      permissions: [
        ADMIN,
        MANAGER, MANAGER_INSIGHTS,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/contracts/contracts.module").then(
        (m) => m.ContractsModule
      ),
  },
  {
    path: "right-holders",
    data: {
      permissions: [
        ADMIN,
        MANAGER, MANAGER_INSIGHTS,
        RIGHT_HOLDER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/right-holders/right-holders.module").then(
        (m) => m.RightHoldersModule
      ),
  },
  {
    path: "channels",
    data: {
      permissions: [
        ADMIN,
        MANAGER, MANAGER_INSIGHTS,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/channels/channels.module").then(
        (m) => m.ChannelsModule
      ),
  },
  {
    path: "drm-youtube-videos",
    data: {
      permissions: [
        ADMIN,
        MANAGER, MANAGER_INSIGHTS,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/drm-youtube-videos/drm-youtube-videos.module").then(
        (m) => m.DrmYoutubeVideosModule
      ),
  },
  {
    path: "monitoring-channels",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/monitoring-channels/monitoring-channels.module").then(
        (m) => m.MonitoringChannelsModule
      ),
  },
  {
    path: "monitoring-youtube-videos",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import(
        "app/modules/monitoring-youtube-vedios/monitoring-youtube-vedios.module"
      ).then((m) => m.MonitoringYoutubeVediosModule),
  },
  {
    path: "categories",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/category/category.module").then(
        (m) => m.CategoryModule
      ),
  },
  {
    path: "energy-level",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/energy-level/energy-level.module").then(
        (m) => m.EnergyLevelModule
      ),
  },
  {
    path: "artists",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/artist/artist.module").then((m) => m.ArtistModule),
  },
  {
    path: "content-library",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/content-library/content-library.module").then(
        (m) => m.ContentLibraryModule
      ),
  },
  {
    path: "drm-content",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/drm-content/drm-content.module").then(
        (m) => m.DrmContentModule
      ),
  },
  {
    path: "import-youtube-content",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/import-youtube-content/import-youtube-content.module").then(
        (m) => m.ImportYoutubeContentModule
      ),
  },

  {
    path: "update-profile",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "royalties-earnings",
    data: {
      permissions: [ADMIN, RIGHT_HOLDER, CREATOR, MANAGER, MANAGER_INSIGHTS,
        MANAGER_INSIGHTS, VIEWER]
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/royalties/royalties.module").then(
        (m) => m.RoyaltiesModule
      ),
  },
  {
    path: "royalties",
    canActivate: [PermissionGuard],
    data: {
      permissions: [ADMIN, RIGHT_HOLDER, CREATOR, MANAGER, MANAGER_INSIGHTS,
        MANAGER_INSIGHTS, VIEWER]
    },
    loadChildren: () =>
      import("app/modules/royalties/royalties.module").then(
        (m) => m.RoyaltiesModule
      ),
  },
  {
    path: "payment-history",
    loadChildren: () =>
      import("app/modules/payment-history/payment-history.module").then(
        (m) => m.PaymentHistoryModule
      ),
  },
  {
    path: "payment-setting",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/payment-setting/payment-setting.module").then(
        (m) => m.PaymentSettingModule
      ),
  },
  // {
  //   path: "pages",
  //   loadChildren: () =>
  //     import("app/pages/full-pages/full-pages.module").then(
  //       (m) => m.FullPagesModule
  //     ),
  // },
  {
    path: "contact-us",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        CREATOR,
      ],
    },
    loadChildren: () =>
      import("app/modules/contact-us/contact-us.module").then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: "inquiry-list",
    canActivate: [PermissionGuard],
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_INSIGHTS, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    loadChildren: () =>
      import("app/modules/inquiry-list/inquiry.module").then(
        (m) => m.InquiryModule
      ),
  },
  {
    path: "browse",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/browse/browse.module").then((m) => m.BrowseModule),
  },
  {
    path: "dashboard-channel",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-channel/dashboard-channel.module").then(
        (m) => m.DashboardChannelModule
      ),
  },
  {
    path: "dashboard-podcast",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-podcast/dashboard-podcast.module").then(
        (m) => m.DashboardPodcastModule
      ),
  },
  {
    path: "dashboard-social-media",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import(
        "app/modules/dashboard-social-media/dashboard-social-media.module"
      ).then((m) => m.DashboardSocialMediaModule),
  },
  {
    path: "dashboard-shop",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-shop/dashboard-shop.module").then(
        (m) => m.DashboardShopModule
      ),
  },
  {
    path: "dashboard-subscriber",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER, MANAGER_INSIGHTS,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-subscriber/dashboard-subscriber.module").then(
        (m) => m.DashboardSubscriberModule
      ),
  },
];
